<template>
  <b-container
    fluid
    class="mb-5"
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="date">Date From</label>
            <b-input
              id="date_from"
              v-model="filter.date_from"
              type="date"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="date">Date To</label>
            <b-input
              id="date_to"
              v-model="filter.date_to"
              type="date"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <label for="vehicle_name">Filter by Vehicle</label>
            <v-select
              id="vehicle_name"
              v-model="selected.vehicle"
              type="text"
              :options="list.vehicles"
              label="name"
              :disabled="state.busy"
            >
              <template #option="{ name }">
                <strong>{{ name }}</strong>
              </template>
              <template #no-options="">
                No Available Vehicle
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <json-excel
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="btn btn-outline-success btn-sm"
            :fetch="myExporter"
            :before-generate="startExport"
            :before-finish="finishExport"
            :disabled="state.exporting || tableSettings.totalRows < 1"
            :fields="exportAs.excel.fields"
            :escape-csv="exportAs.excel.settings.escape"
            :type="exportAs.excel.settings.type"
            :name="excelFileName"
            :header="exportAs.excel.header"
          >
            <span
              v-if="state.exporting"
              class="align-middle"
            >Exporting <i class="ri-loader-2-line" /></span>
            <span
              v-else
              class="align-middle"
            >Export as Excel</span>
          </json-excel>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
        hover
        responsive
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>
        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>
        <template #cell(actions)="row">
          <b-button
            size="sm"
            @click="onShowDetailForm(row.item)"
          >
            View
          </b-button>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-vehicle-schedule-form"
      scrollable
      no-close-on-backdrop
      title="View Detail"
    >
      <b-card no-body>

        <b-form-group>
          <label for="vehicle_name">Vehicle Name</label>
          <b-input
            id="vehicle_name"
            v-model="vehicleSchedule.vehicle_name"
            type="text"
            :disabled="true"
          />
        </b-form-group>

        <b-form-group>
          <label for="vehicle_license_plate">Vehicle License Plate</label>
          <b-input
            id="vehicle_license_plate"
            v-model="vehicleSchedule.vehicle_license_plate"
            type="text"
            :disabled="true"
          />
        </b-form-group>

        <b-form-group>
          <label for="vehicle_type_name">Vehicle Type</label>
          <b-input
            id="vehicle_type_name"
            v-model="vehicleSchedule.vehicle_type_name"
            type="text"
            :disabled="true"
          />
        </b-form-group>

        <b-form-group>
          <label for="departure_date">Departure Date</label>
          <b-input
            id="departure_date"
            v-model="vehicleSchedule.departure_date"
            type="text"
            :disabled="true"
          />
        </b-form-group>

        <b-form-group>
          <label for="departure_time">Departure Time</label>
          <b-input
            id="departure_time"
            v-model="vehicleSchedule.departure_time"
            type="text"
            :disabled="true"
          />
        </b-form-group>

        <b-form-group>
          <label for="arrival_date">Arrival Date</label>
          <b-input
            id="arrival_date"
            v-model="vehicleSchedule.arrival_date"
            type="text"
            :disabled="true"
          />
        </b-form-group>

        <b-form-group>
          <label for="arrival_time">Arrival Time</label>
          <b-input
            id="arrival_time"
            v-model="vehicleSchedule.arrival_time"
            type="text"
            :disabled="true"
          />
        </b-form-group>

        <b-form-group>
          <label for="actual_arrival_date">Actual Arrival Date</label>
          <b-input
            id="actual_arrival_date"
            v-model="vehicleSchedule.actual_arrival_date"
            type="text"
            :disabled="true"
          />
        </b-form-group>

        <b-form-group>
          <label for="actual_arrival_time">Actual Arrival Time</label>
          <b-input
            id="actual_arrival_time"
            v-model="vehicleSchedule.actual_arrival_time"
            type="text"
            :disabled="true"
          />
        </b-form-group>

        <b-form-group>
          <label for="actual_by_name">Actual By</label>
          <b-input
            id="actual_by_name"
            v-model="vehicleSchedule.actual_by_name"
            type="text"
            :disabled="true"
          />
        </b-form-group>

        <b-form-group>
          <label for="actual_at">Actual At</label>
          <b-input
            id="actual_at"
            v-model="vehicleSchedule.actual_at"
            type="text"
            :disabled="true"
          />
        </b-form-group>

      </b-card>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { LogisticsOfficerReportService, LogisticsOfficerExportService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'
import Ripple from 'vue-ripple-directive'
import JsonExcel from 'vue-json-excel'

export default {
  name: 'LogisticsOfficerVehicleScheduleHistories',

  middleware: ['auth', 'logistics-officer'],

  metaInfo () {
    return {
      title: 'Vehicle Schedule Histories'
    }
  },

  components: {
    JsonExcel
  },

  directives: {
    Ripple
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        editing: false,
        busy: false,
        exporting: false
      },
      list: {
        vehicles: []
      },
      filter: {
        date_from: this.dateFormatter(Date.now()),
        date_to: this.dateFormatter(Date.now())
      },
      selected: {
        vehicle: null
      },
      vehicleSchedule: {
        id: 0,
        vehicle_name: '',
        vehicle_license_plate: '',
        vehicle_seat_capacity: '',
        vehicle_type_name: '',
        departure_date: '',
        departure_time: '',
        arrival_date: '',
        arrival_time: '',
        actual_arrival_date: '',
        actual_arrival_time: '',
        actual_by_name: '',
        actual_at: ''
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'vehicle_name' },
          { key: 'departure_at', formatter: this.dateTimeFormatterLong, sortable: true },
          { key: 'arrival_at', formatter: this.dateTimeFormatterLong, sortable: true },
          { key: 'actual_arrival_at', formatter: this.dateTimeFormatterLong, sortable: true }
        ]
      },
      exportAs: {
        excel: {
          header: 'Vehicle Schedule Histories',
          settings: {
            type: 'xls',
            escape: false
          },
          fields: {
            Vehicle: {
              field: 'vehicle_name'
            },
            'Vehicle Type': {
              field: 'vehicle_type_name'
            },
            'Vehicle Plate': {
              field: 'vehicle_license_plate'
            },
            'Vehicle Registration Expiry': {
              field: 'vehicle_registration_expiry'
            },
            'Vehicle Capacity': {
              field: 'vehicle_seat_capacity'
            },
            'Departure At': {
              field: 'departure_at'
            },
            'Arrival At': {
              field: 'arrival_at'
            },
            'Actual Arrival At': {
              field: 'arrival_at'
            },
            'Actual By': {
              field: 'actual_by_name'
            },
            'Actual At': {
              field: 'actual_at'
            }
          }
        }
      }
    }
  },

  computed: {
    excelFileName () {
      return `Vehicle-Schedules-${this.vehicleSchedule.vehicle_name.replaceAll(' ', '-')}-${this.filter.date_from.replaceAll('-', '')}-${this.filter.date_to.replaceAll('-', '')}.xls`
    }
  },

  watch: {
    'selected.vehicle' (value) {
      if (value) {
        this.vehicleSchedule.vehicle = value.id
        this.vehicleSchedule.vehicle_name = value.name
        this.vehicleSchedule.vehicle_type_name = value.vehicle_type_name
        this.vehicleSchedule.vehicle_license_plate = value.license_plate
        this.vehicleSchedule.vehicle_registration_expiry = value.registration_expiry
        this.vehicleSchedule.vehicle_seat_capacity = value.seat_capacity
      } else {
        this.vehicleSchedule.vehicle = 0
        this.vehicleSchedule.vehicle_name = ''
        this.vehicleSchedule.vehicle_type_name = ''
        this.vehicleSchedule.vehicle_license_plate = ''
        this.vehicleSchedule.vehicle_registration_expiry = ''
        this.vehicleSchedule.vehicle_seat_capacity = 0
      }
      this.onFilterChange()
    }
  },

  mounted () {
    core.index()
    this.fetchVehicleList()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await LogisticsOfficerReportService.getVehicleScheduleHistories(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}&filter_date_from=${this.filter.date_from}&filter_date_to=${this.filter.date_to}&filter_vehicle=${this.vehicleSchedule.vehicle}`)
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async myExporter () {
      try {
        const { data } = await LogisticsOfficerExportService.getVehicleScheduleHistories(`filter_status=${this.selected.vehicle?.id}&filter_from=${this.filter.date_from}&filter_to=${this.filter.date_to}`)
        return data
      } catch {
        return []
      }
    },

    async fetchVehicleList () {
      await SharedListService.getVehicles().then(({ data }) => {
        this.list.vehicles = data
      })
    },

    onFilterChange () {
      this.$refs.table.refresh()
    },

    startExport () {
      this.state.exporting = true
    },

    finishExport () {
      this.state.exporting = false
    },

    onShowDetailForm (item) {
      const departureDate = item.departure_at?.split(' ')[0]
      const arrivalDate = item.arrival_at?.split(' ')[0]
      const actualArrivalDate = item.actual_arrival_at?.split(' ')[0]
      this.vehicleSchedule.id = item.id
      this.vehicleSchedule.vehicle_name = item.vehicle_name
      this.vehicleSchedule.vehicle_type_name = item.vehicle_type_name
      this.vehicleSchedule.vehicle_license_plate = item.vehicle_license_plate
      this.vehicleSchedule.vehicle_registration_expiry = item.vehicle_registration_expiry
      this.vehicleSchedule.vehicle_seat_capacity = item.vehicle_seat_capacity
      this.vehicleSchedule.departure_date = departureDate
      this.vehicleSchedule.departure_time = this.timeFormatterTwelveHours(item.departure_at)
      this.vehicleSchedule.arrival_date = arrivalDate
      this.vehicleSchedule.arrival_time = this.timeFormatterTwelveHours(item.arrival_at)
      this.vehicleSchedule.actual_arrival_date = actualArrivalDate
      this.vehicleSchedule.actual_arrival_time = this.timeFormatterTwelveHours(item.actual_arrival_at)
      this.vehicleSchedule.actual_by_name = item.actual_by_name
      this.vehicleSchedule.actual_at = item.actual_at
      this.$bvModal.show('modal-vehicle-schedule-form')
    }
  }
}
</script>
